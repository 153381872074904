import { Api } from "../types";
import * as Http from "./helpers";

export const create = async (url: string, pageTitle: string) => {
  return await Http.post<Api.CreateSessionResponse>(`/api/session/create`, {
    url: url,
    pageTitle: pageTitle,
  });
};

export const submitMessage = async (sessionId: number, msg: Api.UserMessage) => {
  return await Http.post<Api.ServiceMessage>(`/api/session/${sessionId}/message`, msg);
};

export const fetch = async (sessionId: number): Promise<null | Api.FetchSessionResponse> => {
  return await Http.get<Api.FetchSessionResponse>(`/api/session/${sessionId}`);
};

export const endSession = async (sessionId: number): Promise<void> => {
  await Http.get(`/api/session/${sessionId}/end`);
};
