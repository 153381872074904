import styles from "./About.module.css";
import { Column } from "../../components/containers";
import { useScrollToAnchor } from "../../util";
import CTA from "./CTA";
import Features from "./Features";
import Header from "./Header";
import Hero from "./Hero";
import Pricing from "./Pricing";

const About = () => {
  useScrollToAnchor([], 100);
  return (
    <Column className={styles.container}>
      <div className={styles.headerSpacer} />
      <Hero />
      <p className={styles.sectionHeader}>How does it work?</p>
      <Features />
      <p className={styles.sectionHeader} id={"pricing"}>
        Do I have to pay?
      </p>
      <Pricing />
      <p className={styles.sectionHeader} id={"cta"}>
        Go try it! <span className={styles.free}>It's free!</span>
      </p>
      <CTA />
      <p className={styles.sectionHeader}>Any questions?</p>
      <p className={styles.contact}>
        contact us at <a href={"mailto:hello@try-machina.com"}>hello@try-machina.com</a>
      </p>
      <div className={styles.footerSpacer} />
      <Header />
    </Column>
  );
};

export default About;
