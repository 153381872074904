import styles from "./Hero.module.css";
import { TypedText } from "../../components/text";
import { useEffect, useState } from "react";
import { multi, sleep } from "../../util";
import { Row } from "../../components/containers";

const Hero = () => {
  const [trigger0, setTrigger0] = useState<Boolean>(false);
  const [trigger1, setTrigger1] = useState<Boolean>(false);
  const [trigger2, setTrigger2] = useState<Boolean>(false);
  const [trigger3, setTrigger3] = useState<Boolean>(false);

  const activateTriggers = async () => {
    await sleep(700);
    setTrigger0(true); // start writing "MACHINA"
    await sleep(220);
    setTrigger1(true); // write black period after "MACHINA"
    await sleep(1000);
    setTrigger2(true); // make visible first paragraph
    await sleep(1000);
    setTrigger3(true); // make visible second paragraph
  };

  useEffect(() => {
    activateTriggers();
  }, []);

  return (
    <div className={styles.container}>
      <Row gap={0} className={styles.shoutContainer}>
        <img alt="robot head" className={styles.logo} src="/logo.png" />
        <TypedText className={styles.shout} text={"HI. I'M"} speed={1.2} />
        <div style={{ display: "flex" }}>
          {trigger0 && (
            <TypedText
              className={multi(styles.shout, styles.pink, styles.leftSpace)}
              text={"MACHINA"}
              speed={1.2}
            />
          )}
          {trigger1 && <TypedText className={styles.shout} text={"."} speed={0.2} />}
        </div>
      </Row>
      {/* prettier-ignore */}
      <p className={multi(styles.main, trigger2 && styles.visible)}>
        I THINK
        <span className={styles.pink}> CODING INTERVIEWS&nbsp;</span>
        ARE A
        <span className={styles.pink}> BULLSHIT&nbsp;</span>
        WAY TO ASSESS
        <span className={styles.pink}> HUMAN BEINGS</span>.
      </p>
      <p className={multi(styles.main, trigger3 && styles.visible)}>
        I’M AN
        <span className={styles.pink}> AI ASSISTANT&nbsp;</span>
        DESIGNED TO TEACH YOU HOW TO
        <span className={styles.pink}> ACE CODING INTERVIEWS&nbsp;</span>
        IN
        <span className={styles.pink}> REALTIME</span>.
      </p>
    </div>
  );
};

export default Hero;
