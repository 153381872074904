import styles from "./Messages.module.css";
import { useCallback, useEffect, useState } from "react";
import { Api } from "../../types";
import { Language } from "../../types/message-content";
import { multi } from "../../util";
import hljs from "highlight.js";
import "highlight.js/styles/panda-syntax-light.css";

interface FreeTextContent {
  text: string;
}

export const UserMessage = (props: { message: Api.UserMessage }) => {
  const textContent = (): string => {
    switch (props.message.action) {
      case Api.UserMessageAction.Pseudocode:
        return "Help me write some pseudocode.";
      case Api.UserMessageAction.Solution:
        return "Help me derive the whole solution.";
      case Api.UserMessageAction.GeneralApproach:
        return "What type of problem is this?";
      case Api.UserMessageAction.FreeText:
      case Api.UserMessageAction.UserEntryProblemStatement:
        return (props.message.content as FreeTextContent).text;
      case Api.UserMessageAction.AutoProblemStatement:
        return `Here's the page I'm looking at:\n\n<body>...</body>`;
    }
  };

  return (
    <div className={styles.userMessageContainer}>
      <div className={styles.userMessage}>
        <p className={styles.userMessageContent}>{textContent()}</p>
      </div>
    </div>
  );
};

interface ServiceMessageProps {
  createdAt: number;
  message: Api.ServiceMessage;
  language?: Language;
}

export const ServiceMessage = (props: ServiceMessageProps) => {
  const [justCopied, setJustCopied] = useState(false);
  const elementId = `sm-${props.createdAt}`;
  const turnOffJustCopied = useCallback(() => {
    if (justCopied) setJustCopied(false);
  }, [justCopied]);

  useEffect(() => {
    setTimeout(turnOffJustCopied, 1000);
  }, [justCopied]);

  useEffect(() => {
    const element = document.getElementById(elementId);
    if (!!element && containsCodeBlock()) hljs.highlightElement(element);
  }, []);

  const languageHighlightTag = (language?: Language) => {
    switch (language) {
      case Language.Cpp:
        return "language-cpp";
      case Language.Java:
        return "language-java";
      case Language.Python3:
        return "language-python";
      default:
        return undefined;
    }
  };

  const containsCodeBlock = () => {
    switch (props.message.action) {
      case Api.ServiceMessageAction.QueryResponse:
        const rawText = (props.message.content as FreeTextContent).text;
        return rawText.includes("```");
      default:
        return false;
    }
  };

  const textContent = (): string => {
    switch (props.message.action) {
      case Api.ServiceMessageAction.QueryResponse:
        return (props.message.content as FreeTextContent).text;
      case Api.ServiceMessageAction.ClarifyProblem:
        return "Sorry - I couldn't find the question prompt on the page. Can you copy and paste it here?";
      case Api.ServiceMessageAction.ProblemAck:
        return "Got it. How can I help?";
    }
  };

  const onClick = () => {
    navigator.clipboard.writeText(textContent());
    setJustCopied(true);
  };

  return (
    <div className={styles.serviceMessageContainer}>
      <div
        data-tooltip-id={"tooltip"}
        data-tooltip-html={justCopied ? "message copied" : "click to copy<br/>to clipboard"}
        data-tooltip-place={"top"}
        className={multi(styles.serviceMessage)}
        onClick={onClick}
      >
        <pre>
          <code
            id={`sm-${props.createdAt}`}
            className={multi(styles.serviceMessageContent, languageHighlightTag(props.language))}
          >
            {textContent()}
          </code>
        </pre>
      </div>
    </div>
  );
};
