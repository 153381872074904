import { Row } from "../../components/containers";
import { scrollToAnchor } from "../../util";
import styles from "./Header.module.css";

const AnchorLink = (props: { text: string; anchorId: string; children?: React.ReactNode }) => {
  return (
    <div className={styles.anchorLinkContainer}>
      <button onClick={() => scrollToAnchor(props.anchorId, 100)} className={styles.anchorLink}>
        <span>{props.text}</span>
      </button>
      {props.children || null}
    </div>
  );
};

const Header = () => {
  return (
    <div className={styles.header}>
      <div className={styles.logoContainer}>
        <img alt="robot head" className={styles.logo} src="/logo.png" />
        <p className={styles.title}>MACHINA</p>
      </div>
      <Row gap={20}>
        <AnchorLink text={"Features"} anchorId={"features"} />
        <AnchorLink text={"Pricing"} anchorId={"pricing"} />
        <AnchorLink text={"Try it out"} anchorId={"cta"}>
          <span className={styles.freeCallout}>It's free!</span>
        </AnchorLink>
      </Row>
    </div>
  );
};

export default Header;
