import styles from "./TypingIndicator.module.css";

const TypingIndicator = () => {
  return (
    <div className={styles.serviceMessageContainer}>
      <div className={styles.serviceMessage}>
        <div className={styles.typingDot} />
        <div className={styles.typingDot} />
        <div className={styles.typingDot} />
      </div>
    </div>
  );
};

export default TypingIndicator;
