import styles from "./styles.module.css";
import { useCallback, useEffect } from "react";
import { login } from "../../api/user";
import { Column, PopupContainer, Row } from "../../components/containers";
import { FaGoogle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Path } from "../../constants";
import * as ChromeClient from "../../chrome";
import { Button } from "../../components/buttons";

const Login = () => {
  const navigateTo = useNavigate();

  useEffect(() => {
    const redirectToHome = async () => {
      if (await ChromeClient.getLoggedIn()) navigateTo(Path.Home);
    };
    redirectToHome();
  }, [navigateTo]);

  const onLogin = useCallback(
    async (redirectUrl?: string) => {
      if (redirectUrl !== undefined) {
        const urlHash = redirectUrl.split("#")[1];
        const params = new URLSearchParams(urlHash);
        const jwt = params.get("id_token") || "";
        try {
          await login(jwt);
          await ChromeClient.setLoggedIn(true);
          navigateTo(Path.Home);
        } catch {}
      }
    },
    [navigateTo],
  );

  const onClickLogin = () => {
    ChromeClient.initLogin(onLogin);
  };

  return (
    <PopupContainer>
      <Column>
        <p className={styles.title}>MACHINA</p>
        <Row>
          <img alt="robot head" className={styles.logo} src="/logo.png" />
          <p className={styles.hook}>
            LET'S FUCK UP SOME
            <br />
            CODING INTERVIEWS.
          </p>
        </Row>
        <Column gap={8}>
          <Row>
            <Button
              fat
              link
              href={process.env.PUBLIC_URL}
              target="_blank"
              rel="noreferrer noopener"
            >
              Learn more
            </Button>
          </Row>
          <Row>
            <Button fat pink onClick={onClickLogin}>
              <FaGoogle />
              Login with Google
            </Button>
          </Row>
        </Column>
      </Column>
    </PopupContainer>
  );
};

export default Login;
