import { useEffect } from "react";
import * as ChromeClient from "../chrome";
import { Path } from "../constants";
import { redirect } from "react-router-dom";

export const useRedirectToLogin = () =>
  useEffect(() => {
    const isLoggedIn = ChromeClient.getLoggedIn();
    if (!isLoggedIn) redirect(Path.Login);
  }, []);
