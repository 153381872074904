import styles from "./Pricing.module.css";
import { useState } from "react";
import { multi, sleep, useOnEnterViewport } from "../../util";
import { Column, Row } from "../../components/containers";

const Pricing = () => {
  const [trigger0, setTrigger0] = useState<Boolean>(false);
  const [trigger1, setTrigger1] = useState<Boolean>(false);
  const [trigger2, setTrigger2] = useState<Boolean>(false);

  const activateTriggers = async (entry: IntersectionObserverEntry) => {
    if (!entry.isIntersecting) return;
    await sleep(200);
    setTrigger0(true); // show free
    await sleep(1000);
    setTrigger1(true); // show subscription
    await sleep(1000);
    setTrigger2(true); // show parsing
  };

  useOnEnterViewport(activateTriggers, ["pricing"], [], 1);

  return (
    <Column className={styles.container}>
      <Row gap={40} className={styles.pricesContainer}>
        <Column className={multi(styles.priceContainer, trigger0 && styles.visible)}>
          <div className={styles.price}>
            <p>Free Forever</p>
          </div>
          <ul>
            <li>
              <span>One session per day</span>
            </li>
            <li>
              <span>Use on leetcode.com</span>
            </li>
          </ul>
        </Column>
        <Column className={multi(styles.priceContainer, trigger1 && styles.visible)}>
          <div className={styles.price} style={{ backgroundColor: "#ff17ae" }}>
            <p>$10 / Month</p>
          </div>
          <ul>
            <li>
              <span>Unlimited sessions</span>
            </li>
            <li>
              <span>
                Use on any site<sup>*</sup>
              </span>
            </li>
          </ul>
        </Column>
      </Row>
      <Column className={multi(styles.parsedSites, trigger2 && styles.visible)}>
        <p>
          <sup>*</sup> Problem auto-parsing enabled on
        </p>
        <ul>
          <li>
            <span>leetcode.com</span>
          </li>
          <li>
            <span>hackerrank.com</span>
          </li>
          <li>
            <span>coderpad.io</span>
          </li>
          <li>
            <span>codility.com</span>
          </li>
          <li>
            <span>coderbyte.com</span>
          </li>
          <li>
            <span>codeshare.io</span>
          </li>
          <li>
            <span>codesignal.com</span>
          </li>
          <li>
            <span>... and more coming soon!</span>
          </li>
        </ul>
      </Column>
    </Column>
  );
};

export default Pricing;
