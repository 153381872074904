import styles from "./styles.module.css";
import { multi } from "../util";
import * as React from "react";

interface ContainerProps {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode | null;
  spaced?: Boolean;
  gap?: number;
}

export const PopupContainer = (props: ContainerProps) => {
  return <div className={styles.popupContainer}>{props.children}</div>;
};

export const Column = (props: ContainerProps) => ContainerImpl(false, props);
export const Row = (props: ContainerProps) => ContainerImpl(true, props);

const ContainerImpl = (isRow: boolean, props: ContainerProps) => {
  const classes = multi(
    isRow ? styles.row : styles.column,
    props.spaced && styles.spaced,
    props.className,
  );

  return (
    <div className={classes} style={{ ...props.style, gap: `${props.gap || 0}px` }}>
      {props.children}
    </div>
  );
};
