import styles from "./Header.module.css";
import PreSession from "./PreSession";
import { MessageContent } from "../../types";

interface HeaderProps {
  awaitingResponse: boolean;
  isComplete: boolean;
  selectedLanguage?: MessageContent.Language;
  onSelect: (l: MessageContent.Language) => void;
}

const Header = (props: HeaderProps) => {
  return (
    <>
      <div className={styles.header}>
        <div className={styles.logoContainer}>
          <img alt="robot head" className={styles.logo} src="/logo.png" />
          <p className={styles.title}>MACHINA</p>
        </div>
      </div>
      <PreSession {...props} />
    </>
  );
};

export default Header;
