import styles from "./Session.module.css";
import { useCallback, useEffect, useState } from "react";
import TypingIndicator from "./TypingIndicator";
import { Api, MessageContent } from "../../types";
import * as ApiClient from "../../api";
import { useParams } from "react-router-dom";
import { Row } from "../../components/containers";
import { multi, scrollToBottom } from "../../util";
import { ServiceMessage, UserMessage } from "./Messages";
import UserMessageEntry from "./UserMessageEntry";
import ActionPrompts from "./ActionPrompts";
import Header from "./Header";
import * as ChromeClient from "../../chrome";
import { Button } from "../../components/buttons";
import moment from "moment";

const STANDARD_PROMPTS = [
  Api.UserMessageAction.GeneralApproach,
  Api.UserMessageAction.Pseudocode,
  Api.UserMessageAction.Solution,
];

const requiresUserProblemEntry = (_messages: Api.Message[]) => {
  switch (_messages.length) {
    case 0:
      return false;
    default:
      const lastMessage = _messages[_messages.length - 1];
      return (
        lastMessage.source === Api.MessageSource.Service &&
        (lastMessage as Api.ServiceMessage).action === Api.ServiceMessageAction.ClarifyProblem
      );
  }
};

const Session = () => {
  const { sessionIdRaw, windowIdRaw } = useParams();
  const sessionId = Number.parseInt(sessionIdRaw || "-1");
  const windowId = Number.parseInt(windowIdRaw || "-1");
  const [startedAt, setStartedAt] = useState("");
  const [completedAt, setCompletedAt] = useState("");
  const [language, setLanguage] = useState<MessageContent.Language>();
  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const [prompts, setPrompts] = useState<Api.UserMessageAction[]>([]);
  const [messages, setMessages] = useState<Api.Message[]>([]);
  const [isComplete, setIsComplete] = useState(false);

  const initializeSession = async () => {
    setAwaitingResponse(true);
    let session = await ApiClient.Session.fetch(sessionId);
    if (!session) return console.error("Failed to fetch session");
    if (session.completedAt !== null) setIsComplete(true);
    if (session.messages.length === 0 && session.completedAt === null) {
      const domBody = await ChromeClient.getDom(windowId, session.url);
      const userMessage = mkUserMessage(domBody, Api.UserMessageAction.AutoProblemStatement);
      const response = await ApiClient.Session.submitMessage(sessionId, userMessage);
      if (response === null) return console.error("No response after sending problem");
      session.messages = session.messages.concat([userMessage, response]);
    }

    if (requiresUserProblemEntry(session.messages)) setPrompts([]);
    else setPrompts(STANDARD_PROMPTS);
    setMessages(session.messages);
    setAwaitingResponse(false);
    setStartedAt(moment(session.createdAt).format("MMM Do, h:mm A"));
    setCompletedAt(session.completedAt ? moment(session.completedAt).format("h:mm A") : "");
  };

  useEffect(() => {
    initializeSession();
  }, []);

  const mkUserMessage = useCallback(
    (text: string, action: Api.UserMessageAction): Api.UserMessage => {
      const base = { createdAt: Date.now(), source: Api.MessageSource.User };
      switch (action) {
        case Api.UserMessageAction.AutoProblemStatement:
          return {
            ...base,
            action: action,
            content: { dom: text },
          };
        case Api.UserMessageAction.UserEntryProblemStatement:
          return {
            ...base,
            action: action,
            content: { text: text },
          };
        case Api.UserMessageAction.FreeText:
          return {
            ...base,
            action: action,
            content: {
              text: text,
              language: language || MessageContent.Language.Python3,
            },
          };
        case Api.UserMessageAction.GeneralApproach:
        case Api.UserMessageAction.Pseudocode:
        case Api.UserMessageAction.Solution:
          return {
            ...base,
            action: action,
            content: { language: language || MessageContent.Language.Python3 },
          };
      }
    },
    [language]
  );

  const onUserSendMessage = async (text: string, action: Api.UserMessageAction) => {
    const trimmed = text.trim();
    console.log("HERES RAW TEXT", text);
    if (trimmed.length === 0) return;

    const preSendMessages = messages; // intentionally can get stale
    setAwaitingResponse(true);

    const userMessage: Api.UserMessage = mkUserMessage(text, action);
    setMessages([...preSendMessages, userMessage]);

    let serviceMessage = await ApiClient.Session.submitMessage(sessionId, userMessage);
    if (serviceMessage === null) {
      serviceMessage = {
        createdAt: -1,
        content: {
          text: "ERROR: this session has expired. Refresh the page to view its archived state.",
        },
        action: Api.ServiceMessageAction.QueryResponse,
        source: Api.MessageSource.Service,
      };
      setIsComplete(true);
    } else if (serviceMessage.action !== Api.ServiceMessageAction.ClarifyProblem)
      setPrompts(STANDARD_PROMPTS);
    setMessages([...preSendMessages, userMessage, serviceMessage]);
    setAwaitingResponse(false);
    scrollToBottom();
  };

  const endSession = async () => {
    setAwaitingResponse(true);
    await ApiClient.Session.endSession(sessionId);
    await initializeSession();
  };

  const MessageChain = () => {
    return (
      <>
        {messages.map((msg) => {
          if (msg.source === Api.MessageSource.User)
            return <UserMessage key={msg.createdAt} message={msg as Api.UserMessage} />;
          else
            return (
              <ServiceMessage
                createdAt={msg.createdAt}
                key={msg.createdAt}
                message={msg as Api.ServiceMessage}
                language={language}
              />
            );
        })}
      </>
    );
  };

  const EndSessionButton = () => {
    const [considering, setConsidering] = useState(false);
    if (considering)
      return (
        <>
          <p>Are you sure?</p>
          <Button
            onClick={() => {
              setConsidering(false);
              endSession();
            }}
            disable={awaitingResponse}
            className={multi(styles.endSession, styles.confirmEnd)}
          >
            Yes
          </Button>
          <Button
            onClick={() => setConsidering(false)}
            disable={awaitingResponse}
            className={multi(styles.endSession, styles.nevermindEnd)}
          >
            No
          </Button>
        </>
      );
    else
      return (
        <Button
          onClick={() => setConsidering(true)}
          disable={awaitingResponse}
          className={styles.endSession}
        >
          End Session
        </Button>
      );
  };

  return (
    <div className={styles.container}>
      <Row className={styles.headerSpacer}>
        <p>{startedAt}</p>
      </Row>
      <MessageChain />
      {awaitingResponse && <TypingIndicator />}
      {!isComplete && (
        <>
          <ActionPrompts
            prompts={prompts}
            disabled={awaitingResponse}
            emitAction={onUserSendMessage}
          />
          <UserMessageEntry
            requiresUserProblemEntry={requiresUserProblemEntry(messages)}
            awaitingResponse={awaitingResponse || language === undefined}
            onSend={onUserSendMessage}
          />
          <Row className={styles.endSessionContainer} gap={10}>
            <EndSessionButton />
          </Row>
        </>
      )}
      <Row className={styles.footerSpacer}>
        {!!completedAt && <p>Session ended at {completedAt}</p>}
      </Row>
      <Header
        awaitingResponse={awaitingResponse}
        isComplete={isComplete}
        selectedLanguage={language}
        onSelect={(l: MessageContent.Language) => setLanguage(l)}
      />
    </div>
  );
};

export default Session;
