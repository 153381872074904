import styles from "./PreSession.module.css";
import { multi } from "../../util";
import CaptureLayer from "../../components/layers";
import { useState } from "react";
import { MessageContent } from "../../types";

interface LanguageSelectorProps {
  awaitingResponse: boolean;
  isComplete: Boolean;
  selectedLanguage?: MessageContent.Language;
  onSelect: (l: MessageContent.Language) => void;
}

const PreSession = (props: LanguageSelectorProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const languageNotSelected: Boolean = props.selectedLanguage === undefined;

  const setLanguage = (l: MessageContent.Language) => {
    props.onSelect(l);
    setIsOpen(false);
  };

  const Guide = () => {
    return (
      <div className={styles.guideContainer}>
        <p className={styles.bigText} style={{ marginTop: 30 }}>
          Hey There.
        </p>
        <p className={styles.bigText} style={{ marginTop: 10 }}>
          Three things you gotta know:
        </p>
        <p className={styles.bigText}>
          1. I've <b>already read this page</b> to look for a coding problem.
        </p>
        <p className={styles.smallText}>
          If I have trouble finding it, I'll ask you to send the problem to me.
        </p>
        <p className={styles.bigText}>2. I'll give you recommendations for things to ask me.</p>
        <p className={styles.smallText}>
          Click these - they save you time and <b>work better than typing messages yourself</b>.
        </p>
        <p className={styles.bigText}>
          3. When you're done, click the 'END SESSION' button at the bottom.
        </p>
        <p className={styles.smallText}>
          You can't start another session until you've finished this one. Hey, maybe you'll even{" "}
          <b>give me some feedback!</b>
        </p>
        <p className={styles.bigText}>
          Capeesh?{" "}
          <span className={styles.pulsePinkColor}>
            <b>Choose a language</b>
          </span>{" "}
          at the top to get started.
        </p>
      </div>
    );
  };

  const LanguageList = () => {
    if (isOpen)
      return (
        <>
          {Object.entries(MessageContent.Language).map(([_, disp]) => {
            return (
              <div
                key={disp}
                onClick={() => setLanguage(disp)}
                className={multi(styles.language, styles.selectorOpen)}
              >
                <span className={styles.languageText}>{disp}</span>
              </div>
            );
          })}
        </>
      );
    else
      return (
        <div
          onClick={() => setIsOpen(true)}
          className={multi(styles.language, languageNotSelected && styles.empty)}
        >
          <span className={styles.languageText}>{props.selectedLanguage || "Select language"}</span>
        </div>
      );
  };

  if (props.awaitingResponse && languageNotSelected) return null;
  if (props.isComplete) return null;
  return (
    <>
      {(isOpen || languageNotSelected) && (
        <CaptureLayer onClick={() => {}} className={styles.languageNotSelectedCapture}>
          <Guide />
        </CaptureLayer>
      )}
      <div
        className={multi(
          styles.languageSelector,
          languageNotSelected && styles.languageNotSelected,
          isOpen && styles.isOpen
        )}
      >
        <LanguageList />
      </div>
    </>
  );
};

export default PreSession;
