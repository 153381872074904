import styles from "./CTA.module.css";
import { useState } from "react";
import { multi, sleep, useOnEnterViewport } from "../../util";
import { FaChrome } from "react-icons/fa";

const CTA = () => {
  const [trigger0, setTrigger0] = useState<Boolean>(false);

  const activateTriggers = async (entry: IntersectionObserverEntry) => {
    if (!entry.isIntersecting) return;
    await sleep(1000);
    setTrigger0(true); // show button
  };

  useOnEnterViewport(activateTriggers, ["cta"]);

  return (
    <a href={"https://google.com"} className={multi(styles.anchor, trigger0 && styles.visible)}>
      <div className={styles.button}>
        <FaChrome />
        <span>
          Get Machina
          <br />
          on the Chrome Web Store
        </span>
      </div>
    </a>
  );
};

export default CTA;
