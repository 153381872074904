import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Path } from "../../constants";
import * as ChromeClient from "../../chrome";

const extractSearchParams = (search: string): [string, string] | null => {
  const params = new URLSearchParams(search);
  const sessionId = params.get("sessionId");
  const windowId = params.get("windowId");
  if (!sessionId || !windowId) return null;
  return [sessionId, windowId];
};

const Init = () => {
  const navigateTo = useNavigate();

  const nextRoute = async () => {
    const searchParams = extractSearchParams(window.location.search);
    if (!(await ChromeClient.getLoggedIn())) return Path.Login;
    else if (searchParams !== null) return `${Path.Session}/${searchParams[0]}/${searchParams[1]}`;
    else return Path.Home;
  };

  const goToNextRoute = async () => navigateTo(await nextRoute());

  useEffect(() => {
    goToNextRoute();
  }, [navigateTo]);

  return null;
};

export default Init;
