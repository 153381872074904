import { multi } from "../util";
import styles from "./styles.module.css";

interface CaptureLayerProps {
  children?: React.ReactNode | null;
  onClick: () => void;
  className?: string;
}

const CaptureLayer = (props: CaptureLayerProps) => {
  return (
    <div className={multi(styles.captureLayer, props.className)} onClick={props.onClick}>
      {props.children || null}
    </div>
  );
};

export default CaptureLayer;
