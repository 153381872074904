import styles from "./styles.module.css";
import { Column, PopupContainer, Row } from "../../components/containers";
import { useRedirectToLogin } from "../../hooks";
import * as ApiClient from "../../api";
import { useNavigate } from "react-router-dom";
import * as ChromeClient from "../../chrome";
import { Button } from "../../components/buttons";
import { FaCrown, FaInfoCircle, FaPlay } from "react-icons/fa";
import { useCallback, useEffect, useState } from "react";
import { TypedText } from "../../components/text";
import { BASIC_HOSTNAME_WHITELIST, Path, SUBSCRIBER_HOSTNAME_WHITELIST } from "../../constants";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [createSessionErrorMessage, setCreateSessionErrorMessage] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [isSubscriber, setIsSubscriber] = useState(true);
  const [openSessionId, setOpenSessionId] = useState<number>();

  const navigateTo = useNavigate();
  useRedirectToLogin();

  useEffect(() => {
    fetchContext();
  }, []);

  useEffect(() => {
    handleActiveTabUrl();
  }, [isSubscriber]);

  const handleActiveTabUrl = useCallback(async () => {
    const [urlRaw, _] = await ChromeClient.getActiveTabDetails();
    const url = new URL(urlRaw);
    const inBasic = BASIC_HOSTNAME_WHITELIST.includes(url.hostname);
    const inSubscriber = SUBSCRIBER_HOSTNAME_WHITELIST.includes(url.hostname);
    const isDevelopment = await ChromeClient.isDevelopment();
    if (!isSubscriber && !inBasic && inSubscriber)
      setCreateSessionErrorMessage("You have to be subscribed to<br/>use Machina on this site.");
    else if (!inSubscriber && !inBasic && !isDevelopment)
      setCreateSessionErrorMessage(
        `Ouch! This site is<br/>not supported.${
          !isSubscriber ? "<br/>Go try Machina out on LeetCode!" : ""
        }`
      );
  }, [isSubscriber]);

  const fetchContext = async () => {
    const ctx = await ApiClient.User.fetchContext();
    if (ctx === null) return;

    setUserFirstName(ctx.firstName.length < 13 ? ctx.firstName.toUpperCase() : "PAL");
    setIsSubscriber(ctx.isSubscriber);
    setOpenSessionId(ctx.openSessionId);
    setLoading(false);
    if (!ctx.canCreateSession)
      setCreateSessionErrorMessage(
        "You need a subscription in order<br/>to create another session today."
      );
  };

  const executeLogout = async () => {
    await ApiClient.User.logout();
    await ChromeClient.setLoggedIn(false);
    navigateTo(Path.Login);
  };

  const openBilling = async () => {
    const billingUrl = await ApiClient.Billing.init();
    if (!billingUrl) return;
    window.open(billingUrl, "_blank", "noreferrer");
  };

  const onCreateSession = async () => {
    // TODO: validate currently viewed screen is an eligible code interview site (prevent bad UX)
    // on page load, before this is clicked.
    setLoading(true);
    const [activeTabUrl, activeTabTitle] = await ChromeClient.getActiveTabDetails();
    const createResponse = await ApiClient.Session.create(activeTabUrl, activeTabTitle);

    if (createResponse == null)
      return setCreateSessionErrorMessage(
        "An unexpected error occurred.<br/>Could not start session."
      );
    if (createResponse.errorMessage)
      return setCreateSessionErrorMessage(createResponse.errorMessage);

    const windowId = await ChromeClient.getCurrentWindowId();
    if (windowId == undefined) return console.error("failed to get window id");
    ChromeClient.createSessionWindow(createResponse.sessionId, windowId);
  };

  const onResumeSession = useCallback(async () => {
    setLoading(true);
    if (!openSessionId) return console.error("Bad session id");
    const windowId = await ChromeClient.getCurrentWindowId();
    if (windowId == undefined) return console.error("failed to get window id");
    ChromeClient.createSessionWindow(openSessionId, windowId);
  }, [openSessionId]);

  const StartButton = () => {
    return openSessionId === undefined ? (
      <Button
        pink
        disable={loading || createSessionErrorMessage.length > 0}
        data-tooltip-place="top"
        data-tooltip-id="tooltip"
        data-tooltip-html={createSessionErrorMessage}
        onClick={onCreateSession}
      >
        Start
        <FaPlay />
      </Button>
    ) : (
      <Button
        pink
        disable={loading}
        data-tooltip-place="top"
        data-tooltip-id="tooltip"
        data-tooltip-html={"You have an open session.<br/>Click here to resume it."}
        onClick={onResumeSession}
      >
        Resume
        <FaPlay />
      </Button>
    );
  };

  return (
    <PopupContainer>
      <Column gap={10}>
        <Row>
          <img alt="robot head" className={styles.logo} src="/logo.png" onClick={executeLogout} />
          <p className={styles.title}>MACHINA</p>
        </Row>
        <Row className={styles.hookContainer}>
          {!loading && (
            <TypedText
              text={`HELLO ${userFirstName}. WHAT ARE WE COOKING UP TODAY?`}
              speed={3}
              className={styles.hook}
            />
          )}
        </Row>
        <Row>
          <Button onClick={openBilling}>
            Manage subscription
            <FaCrown />
          </Button>
        </Row>
        <Row gap={10}>
          <Button link href={process.env.PUBLIC_URL} target="_blank" rel="noreferrer noopener">
            <FaInfoCircle />
            About
          </Button>
          <StartButton />
        </Row>
      </Column>
    </PopupContainer>
  );
};

export default Home;
