import "@fontsource/monomaniac-one";
import "@fontsource/orbitron/900.css";
import "@fontsource/outfit/700.css";
import { Tooltip } from "react-tooltip";
import { BrowserRouter, MemoryRouter, Route, Routes } from "react-router-dom";
import Login from "./views/login/Login";
import { Path } from "./constants";
import Home from "./views/home/Home";
import Init from "./views/init/Init";
import Session from "./views/session/Session";
import About from "./views/about/About";

const App = () => {
  const isExtension = () => window.location.href.startsWith("chrome-extension://");
  const isExtensionPopup = () => isExtension() && !window.location.href.includes("sessionId");
  const isBrowser = window.location.href.includes("try-machina.com");

  const Router = isExtension() ? MemoryRouter : BrowserRouter;
  return (
    <div id={isExtensionPopup() ? "popup" : "page"}>
      <Router>
        <Routes>
          {isBrowser ? (
            <Route path={Path.Init} element={<About />} />
          ) : (
            <>
              <Route path={Path.Init} element={<Init />} />
              <Route path={Path.Login} element={<Login />} />
              <Route path={Path.Home} element={<Home />} />
              <Route path={Path.Session + "/:sessionIdRaw/:windowIdRaw"} element={<Session />} />
              <Route path={Path.About} element={<About />} />
            </>
          )}
        </Routes>
      </Router>
      <Tooltip id="tooltip" style={{ backgroundColor: "black", textAlign: "center" }} />
      <Tooltip
        id="tooltip-click"
        style={{ backgroundColor: "black" }}
        openEvents={{ mousedown: true }}
        closeEvents={{ mouseup: true }}
        delayHide={600}
      />
    </div>
  );
};

export default App;
