import styles from "./Features.module.css";
import { useState } from "react";
import { multi, scrollToAnchor, sleep, useOnEnterViewport } from "../../util";
import { Column, Row } from "../../components/containers";

const Features = () => {
  const [trigger0, setTrigger0] = useState<Boolean>(false);
  const [trigger1, setTrigger1] = useState<Boolean>(false);
  const [trigger2, setTrigger2] = useState<Boolean>(false);

  const activateTriggers = async (entry: IntersectionObserverEntry) => {
    if (!entry.isIntersecting) return;
    await sleep(200);
    setTrigger0(true); // show step 1
    await sleep(1000);
    setTrigger1(true); // show step 2
    await sleep(1000);
    setTrigger2(true); // show step 3
  };

  useOnEnterViewport(activateTriggers, ["features"]);

  return (
    <div className={styles.container}>
      <Column gap={0} className={multi(styles.stepContainer, trigger0 && styles.visible)}>
        <p id={"features"} className={styles.stepTitle}>
          1. Open up a coding interview prep site
        </p>
        <p className={styles.stepDetails}>
          You can use Machina for free on LeetCode.
          <br />
          To use it on other sites, you'll have to&nbsp;
          <a className={styles.link} href={"#pricing"}>
            subscribe
          </a>
          .
        </p>
      </Column>
      <Row gap={60} className={multi(styles.stepContainer, trigger1 && styles.visible)}>
        <Column className={styles.half}>
          <p className={styles.stepTitle}>
            2. Click 'Start' on the{" "}
            <a
              className={styles.link}
              href={"https://google.com"}
              target="_blank"
              rel="noreferrer noopener"
            >
              Machina Chrome Extension
            </a>
          </p>
          <p className={multi(styles.stepDetails, styles.half)}>
            Machina will open a new chat window - undetectable to the original site - and parse the
            page to understand the question.
          </p>
        </Column>
        <img className={styles.popupImg} src={"/popup.png"} />
      </Row>
      <Row gap={60} className={multi(styles.stepContainer, trigger2 && styles.visible)}>
        <Column className={styles.half}>
          <p className={styles.stepTitle}>3. Ask away!</p>
          <p className={styles.stepDetails}>
            Pseudocode, problem type, runtime analysis, you name it! Most common questions can be
            asked with just one click.
          </p>
        </Column>
        <img className={styles.sessionImg} src={"/session.png"} />
      </Row>
    </div>
  );
};

export default Features;
