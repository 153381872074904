import { multi } from "../util";
import styles from "./styles.module.css";

interface IButtonProps {
  children: React.ReactNode;
  className?: string;
  disable?: Boolean;
  loading?: Boolean;
  fat?: Boolean;
  link?: Boolean;
  pink?: Boolean;
  [x: string]: unknown;
}

export const Button = ({
  children,
  className,
  disable,
  loading,
  fat,
  link,
  pink,
  ...htmlAttributes
}: IButtonProps) => {
  const classes = multi(
    styles.button,
    disable && styles.disabled,
    loading && styles.loading,
    fat && styles.fatButton,
    pink && styles.pink,
    className,
  );
  return link ? (
    <a className={classes} {...htmlAttributes}>
      {children}
    </a>
  ) : (
    <button className={classes} {...htmlAttributes} disabled={!!disable}>
      {children}
    </button>
  );
};
