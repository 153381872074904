import styles from "./UserMessageEntry.module.css";
import { useCallback, useState } from "react";
import { multi, scrollToBottom, sleep } from "../../util";
import { BiSolidSend } from "react-icons/bi";
import { Api } from "../../types";

interface UserMessageEntryProps {
  awaitingResponse: Boolean;
  requiresUserProblemEntry: Boolean;
  onSend: (text: string, action: Api.UserMessageAction) => Promise<void>;
}

const UserMessageEntry = (props: UserMessageEntryProps) => {
  const [text, setText] = useState("");

  const onSend = useCallback(async () => {
    const action = props.requiresUserProblemEntry
      ? Api.UserMessageAction.UserEntryProblemStatement
      : Api.UserMessageAction.FreeText;
    await props.onSend(text, action);
    setText("");
  }, [text, props.requiresUserProblemEntry, props.onSend]);

  return (
    <div className={styles.userEntryRow}>
      <div
        className={multi(
          styles.userEntryContainer,
          props.awaitingResponse && styles.disabledUserEntry
        )}
      >
        <textarea
          disabled={props.awaitingResponse.valueOf()}
          className={styles.userEntry}
          name="feedback"
          spellCheck={false}
          maxLength={4096}
          placeholder={"Or type a message here..."}
          value={text}
          onChange={(event) => setText(event.target.value)}
          onFocus={async () => {
            await sleep(150);
            scrollToBottom();
          }}
        />
        <button
          className={styles.userEntrySend}
          onClick={onSend}
          disabled={props.awaitingResponse.valueOf()}
        >
          <BiSolidSend />
        </button>
      </div>
    </div>
  );
};

export default UserMessageEntry;
