import { useEffect, useState } from "react";
import { sleep } from "../util";

interface TypedTextProps {
  text: string;
  speed: number;
  className?: string;
}

export const TypedText = (props: TypedTextProps) => {
  const [text, setText] = useState("");

  useEffect(() => {
    const typingEffect = async () => {
      for (let i = 0; i <= props.text.length; i++) {
        setText(props.text.substring(0, i));
        await sleep(100 / props.speed);
      }
    };

    typingEffect();
  }, []);

  return <p className={props.className}>{text}</p>;
};
