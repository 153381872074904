import { useEffect } from "react";

export const multi = (...styles: (string | undefined | false)[]): string => {
  return styles.filter((el) => el || false).join(" ");
};

export const genNonce = () => Math.round(Math.random() * 1000000);

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const scrollToBottom = () => {
  window.scroll({ top: document.body.scrollHeight });
};

export const getAnchor = () => {
  const splitUrl = window.location.href.split("#");
  if (splitUrl.length === 1) return "";
  return splitUrl[1];
};

export const useScrollToAnchor = (dependencies: any[] = [], offset: number = 0) =>
  useEffect(() => {
    scrollToAnchor(getAnchor(), offset);
  }, dependencies);

export const scrollToAnchor = (anchorId: string, offset: number = 0) => {
  const element = document.getElementById(anchorId);
  if (!element) return;
  window.scrollTo({ top: element.offsetTop - offset });
};

export const useOnEnterViewport = (
  onEntryEnterViewport: (entry: IntersectionObserverEntry) => void,
  entryIds: string[],
  deps: any[] = [],
  threshold: number = 0.75
) => {
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: threshold,
    };

    const observer = new IntersectionObserver(
      (entries, _) => entries.forEach(onEntryEnterViewport),
      options
    );
    entryIds.forEach((entryId) =>
      observer.observe(document.getElementById(entryId) as HTMLElement)
    );

    return () => {
      observer.disconnect();
    };
  }, deps);
};
