import styles from "./ActionPrompts.module.css";
import { Api } from "../../types";
import { BiSolidSend } from "react-icons/bi";
import { multi } from "../../util";

type UserActionTextMap = {
  [Action in Api.UserMessageAction]: string | null;
};

const USER_ACTION_TEXT: UserActionTextMap = {
  [Api.UserMessageAction.GeneralApproach]: "What type of problem is this?",
  [Api.UserMessageAction.Pseudocode]: "Help me write some pseudocode.",
  [Api.UserMessageAction.Solution]: "Help me derive the whole solution.",
  [Api.UserMessageAction.AutoProblemStatement]: null,
  [Api.UserMessageAction.FreeText]: null,
  [Api.UserMessageAction.UserEntryProblemStatement]: null,
};

interface ActionPromptsProps {
  prompts: Api.UserMessageAction[];
  disabled: boolean;
  emitAction: (text: string, action: Api.UserMessageAction) => void;
}

const COLORS = [
  ["#ff0000", "#000000"], // red
  ["#00ff00", "#000000"], // green
  ["#0000ff", "#000000"], // blue
  ["#11ff21", "#b03131"], // orange
  ["#ffff45", "#96962d"], // yellow
  ["#45ffe9", "#28a697"], // teal
];

const ActionPrompts = (props: ActionPromptsProps) => {
  if (!props.prompts) return null;
  return (
    <div className={styles.actionPromptContainer}>
      {props.prompts.map((action) => {
        const actionText = USER_ACTION_TEXT[action];
        if (actionText === null) return null;
        const bgColor = props.disabled ? "#dddddd" : COLORS[action][0];
        const text = props.disabled ? "" : actionText;
        return (
          <div
            key={action}
            className={multi(styles.actionPrompt, props.disabled && styles.actionPromptDisabled)}
            style={{ backgroundColor: bgColor }}
            onClick={() => props.emitAction(actionText, action)}
          >
            <p>{text}</p>
            <BiSolidSend />
          </div>
        );
      })}
    </div>
  );
};

export default ActionPrompts;
